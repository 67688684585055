<template>
  <div class="table-card">
    <div class="heading">
      Call Tracking Numbers
    </div>
    <div class="info">
      <DataTable :value="numbers" responsiveLayout="scroll" :loading="loading">
        <template #loading>
          Loading Tracking Numbers, please wait...
        </template>
        <template #empty>
          No Tracking Numbers found.
        </template>
        <Column field="attributes.phone_number" header="Phone Number"></Column>
        <Column field="attributes.name" header="Name"></Column>
        <Column field="attributes.location" header="Location">
          <template #body="{data}">
            <Dropdown v-model="data.location" :options="locations" optionLabel="attributes.name"
                      placeholder="Select a Location"
                      @change="updateLocation($event.value, data.attributes)" />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<style scoped lang="scss">
.table-card {
  background: #eee;
  border: 1px solid #aaa;
  margin-bottom: 2rem;

  .heading {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #aaa;
    font-size: 1.5rem;
    color: #333;
  }

  .info {
    padding: 1rem 1.5rem;

    & > div {
      margin-bottom: 10px;
    }
  }
}

</style>

<script>
import { defineComponent, ref } from "vue"
import ApiService from "@/core/services/ApiService"
import _ from "lodash"
import Swal from "sweetalert2/dist/sweetalert2.js"
import store from "@/store"

export default defineComponent({
  name: "CallTrackingNumbers",
  components: {},
  data() {
    const accountNum = store.getters.activeAccountInfo.id

    let numbers
    let locations
    let loading = ref(true)

    return { numbers, accountNum, locations, loading }
  },
  async created() {
    await this.init()
  },
  methods: {
    async init() {
      this.loading = true
      this.numbers = await this.callTrackingNumbers()
      this.locations = await this.accountLocations()
      if (this.numbers && this.locations) {
        this.setLocations()
      }
    },
    async callTrackingNumbers() {
      ApiService.setHeader()
      let resp
      try {
        resp = await ApiService.get(`/accounts/${this.accountNum}/callrail-tracking-numbers`)
      } catch (e) {
        console.error("Problem fetching CallRail tracking numbers", e)
      }
      if (resp) {
        return resp.data.data
      }
    },
    async accountLocations() {
      ApiService.setHeader()
      let resp
      try {
        resp = await ApiService.get(`/accounts/${this.accountNum}/locations`)
      } catch (e) {
        console.error("Problem fetching account locations", e)
      }
      if (resp) {
        return resp.data.data
      }
    },
    setLocations() {
      _.map(this.numbers, (number) => {
        number.location = _.find(this.locations, (o) => {
          if (number.attributes.location_id === o.id) {
            return o.attributes
          }
        })
      })

      this.loading = false
    },
    async updateLocation(location, trackingNumber) {
      ApiService.setHeader()
      let resp
      try {
        resp = await ApiService.get(`/accounts/${this.accountNum}/leads/tracking-numbers/${trackingNumber.phone_number}`)
      } catch (e) {
        console.error("Problem updating tracking number location", e)
      }
      if (resp.data.data.length) {
        await this.continue(resp.data.data, location, trackingNumber)
      }

    },
    async continue(data, location, trackingNumber) {
      let choice = await Swal.fire({
        text: `Are you sure you want to update all ${data.length} leads?`,
        icon: "success",
        confirmButtonText: "I am sure",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        buttonsStyling: false,
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-light-primary",
          cancelButton: "btn btn-danger me-3"
        }
      })

      if (choice.isConfirmed) {
        this.loading = true
        await this.updateCalls(data, location)
        await this.updateTrackingNumberLocation(trackingNumber, location)
      }

      // reload the table either way
      await this.init()

    },
    async updateCalls(data, location) {

      let leads = _.map(data, (lead) => {
        lead.attributes.location_id = location.id
        return { id: lead.id, ...lead.attributes }
      })

      ApiService.setHeader()

      try {
        await ApiService.post(`/accounts/${this.accountNum}/leads`, { data: leads })
      } catch (e) {
        console.error("Problem updating lead locations", e)
      }

    },
    async updateTrackingNumberLocation(trackingNumber, location) {

      ApiService.setHeader()

      try {
        await ApiService.put(`/accounts/${this.accountNum}/locations/${location.id}/callrail-tracking-numbers/${trackingNumber.phone_number}`)
      } catch (e) {
        console.error("Problem updating tracking number location", e)
      }

    }
  }
});
</script>
