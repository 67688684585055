<template>
  <hr v-if="indexValue != 0" />
  <div :class="callrailInfo.errors && callrailInfo.errors.message ? 'error' : ''">
    <label for="company_id">Company ID</label>
    <input class="form-control form-control-solid" id="company_id" type="text"
           v-model="callrailCompanyId">
  </div>
  <div :class="callrailInfo.errors && callrailInfo.errors.message ? 'error' : ''">
    <label for="company_key">Company Key</label>
    <input class="form-control form-control-solid" id="company_key" type="text"
           v-model="callrailCompanyKey">
  </div>
  <button class="btn btn-danger btn-sm d-flex ms-auto" @click="deleteConfig(id ? id : key)">
    delete
  </button>
  <span class="error"
        v-if="callrailInfo.errors && callrailInfo.errors.message">{{ callrailInfo.errors.message }}</span>
</template>

<style scoped lang="scss">
div.error {
  label, input[type="text"] {
    color: red;
  }

  input[type="text"] {
    border: 1px solid red;
  }
}
span.error {
  color: red;
  font-style: italic;
  font-size: 12px;
}
</style>

<script>
import {defineComponent, ref} from "vue"
import ApiService from "@/core/services/ApiService"
import Swal from "sweetalert2/dist/sweetalert2.js"
import store from "@/store"

export default defineComponent({
  name: "CallrailInfo",
  emits: ["updateCallrailCompanyId", "updateCallrailCompanyKey", "removeCallRailConfig"],
  props: {
    callrailInfo: Object,
    index: Number
  },
  data(props) {
    const accountNum = store.getters.activeAccountInfo.id
    let id = ref(props.callrailInfo.id).value
    let key = ref(props.callrailInfo.key).value
    let callrailCompanyId = ref(props.callrailInfo.company_id).value
    let callrailCompanyKey = ref(props.callrailInfo.company_key).value
    let indexValue = ref(props.index).value

    return {
      accountNum,
      indexValue,
      id,
      key,
      callrailCompanyId,
      callrailCompanyKey
    }
  },
  methods: {
    async deleteConfig(value) {
      if (value == this.id) {
        // delete from API
        ApiService.setHeader()

        let resp
        try {
          resp = await ApiService.delete(`/accounts/${this.accountNum}/callrail-configs/${this.id}`,
            {})
        } catch (e) {
          console.error("Problem deleting callrail config", e)
        }

        if (resp.status == 200) {
          Swal.fire({
            text: "Successfully deleted CallRail config",
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          this.$emit("removeCallRailConfig", value)
        } else {
          Swal.fire({
            text: "Error deleting CallRail config",
            icon: "error",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
        }
      } else if (value == this.key) {
        // remove from local data set
        this.$emit("removeCallRailConfig", value)
      }
    }
  },
  watch: {
    callrailCompanyId(value) {
      this.callrailCompanyId = value
      let attrs = {}
      if (this.key) {
        attrs = {key: this.key, company_id: value}
      } else {
        attrs = {id: this.id, company_id: value}
      }
      this.$emit("updateCallrailCompanyId", attrs)
    },
    callrailCompanyKey(value) {
      this.callrailCompanyKey = value
      let attrs = {}
      if (this.key) {
        attrs = {key: this.key, company_key: value}
      } else {
        attrs = {id: this.id, company_key: value}
      }
      this.$emit("updateCallrailCompanyKey", attrs)
    }
  }
});
</script>
