
import { defineComponent, ref } from "vue"
import CallTrackingNumbers from "@/components/connections/CallTrackingNumbers.vue"
import ApiService from "@/core/services/ApiService"
import _ from "lodash"
import Swal from "sweetalert2/dist/sweetalert2.js"
import store from "@/store"
import Callrail from "@/components/connections/Callrail.vue"

export default defineComponent({
  name: "Call Tracking",
  components: {
    CallTrackingNumbers,
    Callrail
  },
  data() {
    // @TODO account store info needs to be updated
    const accountNum = store.getters.activeAccountInfo.id
    const dummyCallRailPair = {
      key: 1,
      company_id: "",
      company_key: ""
    }
    const connectionInfo = [
      {
        type: "CallRail",
        callbackURL: "https://footprint-api.hlmtech.com/rx/callrail",
        callrailPairs: [] as Record<string, any>[]
      }
    ]

    let loading = ref<boolean>(false)
    let key = 1

    return {
      loading,
      key,
      dummyCallRailPair,
      connectionInfo,
      accountNum
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    initConnectionInfo() {
      this.connectionInfo = [
        {
          type: "CallRail",
          callbackURL: "https://footprint-api.hlmtech.com/rx/callrail",
          callrailPairs: [] as Record<string, any>[]
        }
      ]
    },
    async init() {
      this.initConnectionInfo()
      let callRails = await this.callRailConnections()
      if (callRails.length > 0) {
        callRails.forEach(info => {
          this.connectionInfo[0].callrailPairs.push({ id: info.id, ...info.attributes })
        })
      } else {
        this.connectionInfo[0].callrailPairs.push(this.dummyCallRailPair)
      }
    },
    async saveConnections() {
      this.loading = true

      await Promise.all(this.connectionInfo.map(async (el) => {
        switch (el.type) {
          case "CallRail":
            await this.storeCallRail(el)
            break
          default:
            console.log("default")
            break
        }
      }))

    },
    async callRailConnections() {
      ApiService.setHeader()

      let resp
      try {
        resp = await ApiService.get(`/accounts/${this.accountNum}/callrail-configs`)
      } catch (e) {
        console.error("Problem fetching callrails", e)
      }

      if (resp) {
        return resp.data.data
      }
    },
    async storeCallRail(info) {
      let errors = false

      _.map(info.callrailPairs, c => {
        let error = {
          message: "CallRail config must have 'company_id' and 'company_key'",
          key: c.key,
          id: c.id ? c.id : null
        }

        if (c.company_id == "" || c.company_key == "") {
          error.message = "CallRail config must have 'company_id' and 'company_key'"
          c.errors = error
          errors = true
        } else if (c.company_id == "125374892136" || c.company_key == "123-000-9999") { // default data
          error.message = "CallRail config can't be demo data"
          c.errors = error
          errors = true
        } else {
          c.errors = {}
        }
      })

      if (errors) {
        this.loading = false
        return
      }

      ApiService.setHeader()

      let resp
      try {
        resp = await ApiService.post(`/accounts/${this.accountNum}/callrail-configs`,
          { data: _.map(info.callrailPairs, info => _.omit(info, ["key"])) })
      } catch (e) {
        console.error("Problem storing callrail config", e)
      }

      if (resp.status == 201) {
        let choice = await Swal.fire({
          text: "Successfully added CallRail config",
          icon: "success",
          confirmButtonText: "Ok",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary"
          }
        })

        if (choice) {
          this.loading = false
          await this.init()
          await this.displayStatus(resp.data)
        }
      } else if (resp.status == 207) {
        await Swal.fire({
          text: "Handled multiple CallRail config types",
          icon: "success",
          confirmButtonText: "Ok",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary"
          }
        })

        this.loading = false
        await this.init()
        await this.displayMixedStatus(resp.data)
      } else {
        await Swal.fire({
          text: "Error adding CallRail config",
          icon: "error",
          confirmButtonText: "Ok",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary"
          }
        })
      }

    },
    async reloadCallTracking() {
      await this.init()
    },
    async displayStatus(value) {
      let good: any = []
      let bad: any = []

      let grouped = _.groupBy(value, (i) => i._meta.status_check.cr.status_check)

      _.filter(grouped["true"], (item) => {
        good.push((_.find(this.connectionInfo[0].callrailPairs, o => o.id == item._attributes.id))?.company_key)
      })

      _.filter(grouped["false"], (item) => {
        bad.push((_.find(this.connectionInfo[0].callrailPairs, o => o.id == item._attributes.id))?.company_key)
      })

      good = good.join(", ")
      bad = bad.join(", ")

      let resp

      if (good.length > 0 && bad.length > 0) {
        resp = `Successfully added ${good} but ${bad} failed`
      } else if (good.length > 0) {
        resp = `Successfully added ${good} connection`
      } else {
        resp = `Couldn't add ${bad} connection`
      }

      await Swal.fire({
        text: resp,
        icon: bad.length > 0 ? "error" : "success",
        confirmButtonText: "Ok",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-light-primary"
        }
      })
    },
    async displayMixedStatus(value) {
      let good: any = []
      let bad: any = []

      let grouped = _.groupBy(value, "status")

      _.filter(grouped["fulfilled"], (item) => {
        good.push((this.connectionInfo[0].callrailPairs.find((o) => o.id == item.value._attributes.id))?.company_key)
      })

      bad = bad.concat(grouped["rejected"].map((i) => i.value))

      good = good.join(", ")
      bad = bad.join(", ")

      let resp

      if (good.length > 0 && bad.length > 0) {
        resp = `Successfully added ${good} but ${bad}`
      } else if (good.length > 0) {
        resp = `Successfully added ${good} connection`
      } else {
        resp = `Couldn't add ${bad}`
      }

      await Swal.fire({
        text: resp,
        icon: bad.length > 0 ? "error" : "success",
        confirmButtonText: "Ok",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-light-primary"
        }
      })
    }
  },
});
