<template>
  <div class="admin-card">
    <div class="heading">
      Callrail
    </div>
    <div class="info">
      <div v-if="connectionRef.callbackURL">
        <label for="callbackURL">Callback URL</label>
        <input class="form-control form-control-solid" id="callbackURL"
               type="text" v-model="connectionRef.callbackURL" disabled>
      </div>
      <CallrailKeys v-for="(callrail, index) in connectionRef.callrailPairs"
                    :key="callrail.id ? callrail.id : callrail.key"
                    :callrailInfo="callrail"
                    :index="index"
                    @updateCallrailCompanyId="updateCallrailCompanyId"
                    @updateCallrailCompanyKey="updateCallrailCompanyKey"
                    @removeCallRailConfig="removeCallRailConfig" />
      <div v-if="connectionRef.type == 'CallRail'" class="add-fields">
        <Button @click="additionalCallrail()">Add</Button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.add-fields {
  margin-top: 1rem;
  justify-content: flex-end;

  button {
    width: auto !important;
  }
}
</style>

<script>
import { defineComponent, ref } from "vue"
import CallrailKeys from "@/components/connections/CallrailKeys.vue"

export default defineComponent({
  name: "Callrail",
  components: {
    CallrailKeys
  },
  emits: ["reloadCallTracking"],
  props: {
    connection: Object
  },
  data(props) {
    let connectionRef = ref(props.connection).value

    return {
      connectionRef
    }
  },
  methods: {
    additionalCallrail() {
      this.connectionRef.callrailPairs.push({
        key: this.connectionRef.callrailPairs.length + 1,
        company_id: "",
        company_key: ""
      })
    },
    updateCallrailCompanyId(info) {
      this.connectionRef.callrailPairs.map((p) => {
        if (info.id && p.id === info.id) {
          p.company_id = info.company_id
        } else if (p.key && p.key === info.key) {
          p.company_id = info.company_id
        }
      })
    },
    updateCallrailCompanyKey(info) {
      this.connectionRef.callrailPairs.map((p) => {
        if (info.id && p.id === info.id) {
          p.company_key = info.company_key
        } else if (p.key && p.key === info.key) {
          p.company_key = info.company_key
        }
      })
    },
    removeCallRailConfig(id) {
      this.connectionRef.callrailPairs = this.connectionRef.callrailPairs.filter(item => {
        if (item.key != id && item.id != id) return item
      })
      this.$emit("reloadCallTracking")
    },
  }
});
</script>
